<template>
  <section class="case">
    <div class="banner">
      <div class="bannerContent_">
        <div class="bannerTitle animate__animated animate__fadeInUp">有智慧的系统</div>
        <div class="bannerContent animate__animated animate__fadeInUp animate__delay-1s">
          全贸通供应链管理系统以数智化方式，构筑全面、精细化的企业管理体系，20年国际贸易进出口经验全面数字化，集成化，赋能企业管理，让工作更轻松、企业更高效。
        </div>
      </div>
    </div>
    <div class="title">
      <div>案例展示</div>
    </div>
    <div class="caseList">
      <img @click="$router.push('/relation')" class="caseItem" src="@/assets/img/case/case1.jpg" alt="" />
      <!-- <img class="caseItem" src="@/assets/img/case/case2.jpg" alt="" /> -->
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.case {
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner {
    margin-bottom: 80px;
    width: 100%;
    height: 536px;
    background-image: url("../assets/img/case/banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 120px;
    .bannerTitle {
      font-size: 60px;
      color: white;
      margin-bottom: 60px;
    }
    .bannerContent {
      font-size: 24px;
      line-height: 50px;
      color: white;
      width: 750px;
    }
  }
  .title {
    height: 2px;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cccccc;
    width: 435px;
    margin-bottom: 60px;
    div {
      background: white;
      padding: 0 20px;
    }
  }
  .caseList {
    display: flex;
    margin-bottom: 100px;
    img {
      width: 678px;
      height: 575px;
      /* &:first-child {
        margin-right: 80px;
      } */
      &:hover {
        transform: scale(1.1);
        transition: all 1s;
      }
    }
  }
}
</style>
